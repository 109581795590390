<template>
  <v-container fluid>
    <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "scanner.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <span class="headline"></span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('scanner.table_search')"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="computedScanners"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:item.sinceLastHeartbeat="{ item }" >
        <v-chip 
          v-if="item.sinceLastHeartbeat"
          :color="getColor(item.lastUpdateOn)"
          dark
        >
          {{ item.sinceLastHeartbeat }}
        </v-chip>        
      </template>
      <template v-slot:item.gotoScanRecords="{ item }">
        <v-icon
          medium
          class="mr-2"
          color="green lighten-1"
          @click="gotoScanRecords(item)"
        >
          video_camera_front
        </v-icon>
      </template> 
    </v-data-table>
     <div style="width: 100%; height: 700px">
      <d3-geo-leaf-icons
        tile-provider="Stamen.TonerLite"
        :geojson-object="geoJson"
        color="#FF6600"
        :auto-fit-bounds="true"
        >    
      </d3-geo-leaf-icons>     
    </div>    
  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 28px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'  
import { CallDialog, AppSyncSubscriber } from '@/utils'
import store from '@/store'

import * as d3geo from 'vue-d3-geo'
import 'vue-d3-geo/dist/vue-d3-geo.css'

import * as moment from 'moment'
import 'moment-duration-format'

export default {
  data: () => ({
    search: '',
    dialog: false,
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 10,
      sortBy: 'lastUpdateOn',
      totalItems: 0,
      rowsPerPageItems: [10,10,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]
    },
  }),
  created () {
    this.startTimer()
  },
  computed: {
    headers () {
      return [
        { text: this.$t('scanner.table_listing_id'), value: 'listingId', align: 'left', sortable: false},
        { text: this.$t('scanner.table_listing_name'), value: 'listingName', align: 'left', sortable: false},        
        { text: this.$t('scanner.table_core'), value: 'coreName', align: 'left', sortable: false},
        { text: this.$t('scanner.table_terminal_name'), value: 'terminalName', align: 'left', sortable: false},
        { text: this.$t('scanner.table_terminal_key'), value: 'terminalKey', align: 'left', sortable: false},
        { text: this.$t('scanner.table_room_code'), value: 'roomCode', align: 'left', sortable: true},
        { text: this.$t('scanner.table_ip'), value: 'localIp', align: 'left', sortable: false},
        { text: this.$t('scanner.table_latitude'), value: 'latitude', align: 'left', sortable: false},
        { text: this.$t('scanner.table_longitude'), value: 'longitude', align: 'left', sortable: false},
        { text: this.$t('scanner.table_last_update'), value: 'lastUpdateOn', align: 'left', sortable: true},
        { text: this.$t('scanner.table_last_heartbeat'), value: 'sinceLastHeartbeat', align: 'left', sortable: false},
        { text: this.$t('reservation.table_goto_records'), value: 'gotoScanRecords', align: 'left', sortable: false }
      ]
    },    
    geoJson () {
      const features = this.scanners.map(scanner => {
        return {
          type: "Feature",
          properties: { 
            scannerName: scanner.terminalName,
            lastUpdateOn: scanner.lastUpdateOn
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(scanner.longitude),
              parseFloat(scanner.latitude)
            ]
          }
        }
      })

      const result = {
        type: "FeatureCollection",
        features: features
      }

      return result

    },
    computedScanners() {
      const results = this.$store.state.scanner.scanners.map(scanner => {
        const newScanner = Object.assign({}, scanner)
        newScanner.sinceLastHeartbeat = this.getDurationText(newScanner.lastUpdateOn)
        return newScanner
      })
      return results
    },
    ...mapState({
      scanners: state => state.scanner.scanners,
      nowTime: state => state.scanner.nowTime,
    }),
  },
  async beforeRouteEnter (to, from, next) {
    AppSyncSubscriber.connect('scanner', 'onCreateScanner', null, 'refreshScanners')

    const error = await store.dispatch('scanner/loadScanners').catch(error => {      
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  // async beforeRouteUpdate (to, from, next) {
  //   const error = await store.dispatch('scanner/loadScanners').catch(error => {      
  //     return error
  //   })

  //   if (error) {
  //     CallDialog(error)
  //   } else {
  //     next()
  //   }
  // },
  methods: {
    getDurationText (lastUpdateOn) {
      // console.log('lastUpdateOn:', lastUpdateOn)
      if (lastUpdateOn) {
        const durationMilliseconds = (new Date(this.nowTime)).getTime() - Date.parse(lastUpdateOn)
        // console.log('durationMilliseconds:', durationMilliseconds)
        return moment.duration(durationMilliseconds).format("d [days] hh:mm:ss[s]")
      } else {
        return ''
      }
      
    },
    getDuration (lastUpdateOn) {
      if (lastUpdateOn) {
        const durationMilliseconds = (new Date(this.nowTime)).getTime() - Date.parse(lastUpdateOn)
        // console.log('durationMilliseconds:', durationMilliseconds)
        return durationMilliseconds
      }
    },
    getColor (lastUpdateOn) {
      const diff = this.getDuration(lastUpdateOn)

      if (diff < 600000) {
        return 'green'
      } else if (diff >= 600000 && diff <= 1200000) {
        return 'orange'
      } else {
        return 'red'
      }
    },
    ...mapActions({
      // loadScanners: 'scanner/loadScanners',
      startTimer: 'scanner/startTimer',
      gotoScanRecords: 'scanner/gotoScanRecords',
    })
  },
  components: {
    D3GeoLeafIcons: d3geo.D3GeoLeafIcons,
  }
}
</script>